<template>
  <div class="salesHeader">
    <div class="logo">
      <img src="@/assets/img/header-logo.png" alt="" />
    </div>
    <div class="goOut" v-if="showOut">
      <span class="userName">{{ realName }}</span>
      <span>，</span>
      <span class="out" @click="goOut">退出</span>
    </div>
  </div>
</template>
<script>
import { Logout } from '@/api/login'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      magId: null,
      realName: sessionStorage.getItem('realName'),
      notLoginPath: ['/statistics'] // 不需要登陆就可以访问的页面
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      magsStatus: 'magsStatus'
    }),
    showOut() {
      // 用户未登录,并且访问的页面不需要登陆,隐藏退出登录按钮
      if (!sessionStorage.getItem("token") && this.notLoginPath.includes(this.$route.path)) {
        return false
      } else {
        return true
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['setMagId', 'setMemberId', 'setPlatMagId', 'setPlatTenantId', 'setPlatMagName', 'setMagsStatus']),
    goOut() {
      Logout().then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
        } else {
          sessionStorage.setItem('token', '')
          this.$router.push('/')
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.salesHeader {
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  .logo {
    img {
      height: 35px;
    }
  }
  .goOut {
    line-height: 24px;
    font-size: 14px;
    color: #bec7ef;
    display: flex;
    align-items: center;
    .out {
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
}
</style>
