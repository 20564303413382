<template>
  <div :class="chartTable ? 'center_table' : 'conter_3'">
    <div class="list-btn fr" v-if="showListBtn && tableData.length > 0" @click="showMore">
      任务列表
    </div>
    <div class="delete fr" v-if="showDelete && itemsShowDelete">
      <el-button :disabled="deleteDisabled" size="small" round icon="el-icon-delete" @click="deleteDialogVisible = true">删除</el-button>
    </div>
    <!-- 删除弹框 -->
    <el-dialog :visible.sync="deleteDialogVisible" width="300px" :show-close="true">
      <p>确定删除吗</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteItems">确 定</el-button>
      </span>
    </el-dialog>
    <div class="up-bottom-btns fr" v-if="showUpBottom">
      <div class="up delete fr" v-if="showUp">
        <el-button size="small" round icon="el-icon-top" @click="toTop">上移</el-button>
      </div>
      <div class="bottom delete fr" v-if="showBottom">
        <el-button size="small" round icon="el-icon-bottom" @click="toBottom">下移</el-button>
      </div>
    </div>
    <div v-if="chooseList.length > 0"></div>
    <el-table
      :key="`${showChoose}-${hasSelect}`"
      ref="simpleTable"
      stripe
      style="width: 100%;height: 100%;overflow-y:scroll;pointer-events:all"
      :data="constantTableData"
      :row-key="getRowKeys"
      :max-height="maxHeight"
      :border="showBorder"
      :row-class-name="tableRowClassName"
      :class="[listBtnClass, nonePointerEvents]"
      @sort-change="handleChangeSort"
      @select="handleSelect"
      @selection-change="handleSelectionChange"
      @row-click="handleRowClick"
      @current-change="handleCurrentChange"
    >
      <el-table-column v-if="hasSelect" type="selection" :reserve-selection="true" width="55"></el-table-column>
      <el-table-column v-if="showChoose" width="40px" align="center">
        <template slot-scope="scope">
          <div class="choose-style" :class="chooseId === scope.row.id ? 'is-choose-style' : ''">
            <div class="child" v-if="chooseId === scope.row.id"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="!noSerial" type="index" :label="serialText" width="48px" align="center">
        <template slot-scope="scope">
          <span :class="isHighlight && isMagIdInClusterMagIds(scope.row)" style="color: green" @click="handleClickTitle(columnData[0], scope.row)" class="tbTitle">
            <span v-if="initObj.page">{{ (initObj.page - 1) * initObj.size + scope.$index + 1 }}</span>
            <span :class="'serial' + (scope.$index + 1)" v-else>{{ scope.$index + 1 }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columnData"
        :key="item.prop"
        :prop="item.prop"
        :label="item.title"
        :fixed="item.fixed"
        :align="item.align ? item.align : alignText ? alignText : 'center'"
        :width="item.width ? item.width : ''"
        :sortable="typeof item.notSortable === 'string' ? item.notSortable : !item.notSortable"
        :show-overflow-tooltip="true"
        :render-header="item.tooltip ? renderHeaderMethods : notRenderHeaderMethods"
      >
        <template slot-scope="scope">
          <span v-if="item.special" class="tbTitle" :class="isHighlight && isMagIdInClusterMagIds(scope.row)">
            <span
              @click="
                () => {
                  handleClickTitle(item, scope.row)
                }
              "
            >
              {{
                item.options
                  ? scope.row[item.options[item.prop]] || scope.row[item.options[item.prop]] === 0
                    ? scope.row[item.options[item.prop]]
                    : '--'
                  : scope.row[item.prop] || scope.row[item.prop] === 0
                  ? scope.row[item.prop]
                  : '--'
              }}
            </span>
            <el-popover v-if="item.hasPop" placement="bottom" width="220" trigger="click" :content="popContentVal || ''">
              <i
                style="margin-left: 3px;cursor: pointer;color: #0ba9e1"
                slot="reference"
                @click="
                  () => {
                    handleSearchPopContent(scope.row)
                  }
                "
                class="el-icon-s-operation"
              ></i>
            </el-popover>
          </span>
          <span v-else-if="item.edit">
            <el-input
              v-if="scope.row.isSet && scope.row.rowIndex === index"
              ref="editInput"
              @change="changeInput(content, item.prop, $event, scope.$index)"
              @blur="changeInputBlur"
              v-model="content"
              placeholder="请输入内容"
            ></el-input>
            <span v-else @click="changeInputContent(scope.row[item.prop], item.prop, $event, scope.$index, scope)">
              {{
                item.options
                  ? item.options[scope.row[item.prop]] || item.options[scope.row[item.prop]] === 0
                    ? item.options[scope.row[item.prop]]
                    : '--'
                  : scope.row[item.prop] || scope.row[item.prop] === 0
                  ? scope.row[item.prop]
                  : '--'
              }}
            </span>
          </span>
          <span v-else-if="item.radio" class="radio">
            <el-radio v-model="scope.row.defaultRadio" :label="true" @change="clickRadio(scope.row, scope.$index)"> </el-radio>
          </span>
          <span v-else-if="item.dateTimePicker && !scope.row.notShowDateTimePicker">
            <el-date-picker ref="dateTimePicker" format="yyyy-MM-dd" :clearable="false" placeholder="反馈时间" v-model="scope.row[item.prop]" type="datetime" @change="dateTimePickerChange(scope.row)">
            </el-date-picker>
          </span>
          <span v-else-if="item.select" class="item-select">
            <span style="display:none">{{ test }}</span>
            <el-select v-model="scope.row[item.prop]" placeholder="请选择" @change="handleItemSelectChange($event, scope.row, 'select', item.prop)">
              <el-option v-for="it in item.selectOptions || scope.row.selectOptions" :key="it.value" :label="it.label" :value="it.value"> </el-option>
            </el-select>
          </span>
          <span v-else-if="item.input" class="item-input" :title="scope.row[item.prop] ? scope.row[item.prop] : item.placeholder">
            <el-input :placeholder="item.placeholder" v-model="scope.row[item.prop]" @change="handleItemSelectChange($event, scope.row, 'input')"></el-input>
          </span>
          <!-- 刊名 -->
          <span v-else-if="item.prop === 'magName'" class="clickUrl" @click="$emit('magNameClick', scope.row)">
            {{ scope.row[item.prop] }}
          </span>
          <span :class="isHighlight && isMagIdInClusterMagIds(scope.row)" v-else>
            {{
              item.options
                ? item.options[scope.row[item.prop]] || item.options[scope.row[item.prop]] === 0
                  ? item.options[scope.row[item.prop]]
                  : '--'
                : scope.row[item.prop] || scope.row[item.prop] === 0
                ? scope.row[item.prop]
                : '--'
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="showBtn" :label="showBtnText">
        <template slot-scope="scope">
          <el-button type="text" class="btn" :class="!scope.row.activeBtn ? 'disabledBtn' : 'btn1'" :disabled="!scope.row.activeBtn" @click="btnClick(scope.row)">{{ btnText }} </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showOperate"
        align="center"
        :fixed="showOperateFixed"
        :label="showOperateText"
        :render-header="showOperateTooltip ? showOperateRenderHeaderMethods : notRenderHeaderMethods"
        :width="showOperateWidth"
      >
        <template slot-scope="scope">
          <div class="operate-btns">
            <el-upload v-if="showUpload" name="file" :accept="uploadAcceptType" class="upload" :action="uploadUrl" :show-file-list="false" :auto-upload="true" :on-success="handleUploadSuccess">
              <el-button class="upload-btn" size="small" type="primary" @click="uploadClick(scope.row)">{{ uploadText }}</el-button>
            </el-upload>
            <el-popover v-if="showPreview" popper-class="popover-sty" placement="left" width="172" trigger="click" @show="handlePreviewURL(scope.row)">
              <div class="btn_fx" v-if="showQrcode">
                <el-button size="yl" round @click="handlePreviewJump(scope.row)">预览</el-button>
                <div class="btn_fxd">分享到</div>
                <div :id="'qrcode' + scope.row.id"></div>
              </div>
              <div v-else>{{ showQrcodeText }}</div>
              <span slot="reference" class="preview">预览</span>
            </el-popover>
            <el-button
              type="text"
              class="btn"
              v-if="showBtn1 && !scope.row.isHideBtn1"
              :class="[isDisabledClick && !scope.row.activeBtn1 ? 'disabledBtn' : 'btn1', scope.row.btn1Class ? 'btn1Class' : '']"
              :disabled="isDisabledClick && !scope.row.activeBtn1"
              @click="btn1Click(scope.row)"
              >{{ scope.row.btn1Text || btn1Text }}
            </el-button>
            <el-button
              type="text"
              class="btn"
              v-if="showBtn2 && !scope.row.isHideBtn2"
              :class="[isDisabledClick && !scope.row.activeBtn2 ? 'disabledBtn' : 'btn2', scope.row.btn2Class ? 'btn1Class' : '']"
              :disabled="isDisabledClick && !scope.row.activeBtn2"
              @click="btn2Click(scope.row)"
              >{{ scope.row.btn2Text || btn2Text }}</el-button
            >
            <el-button type="text" class="btn btn3Class" v-if="showBtn3 && !scope.row.isHideBtn3" @click="btn3Click(scope.row)">{{ scope.row.btn3Text || btn3Text }}</el-button>
            <el-button type="text" class="btn btn4Class" v-if="showBtn4 && !scope.row.isHideBtn4" @click="btn4Click(scope.row)">{{ scope.row.btn4Text || btn4Text }}</el-button>
            <el-upload
              v-if="showUploadFile"
              ref="uploadExcel"
              name="file"
              :auto-upload="true"
              :before-upload="beforeUploadFile"
              :on-success="handleSuccess"
              :on-error="handleError"
              :data="fileUploadForm"
              class="upload-btn"
              :action="fileUploadUrl"
              :accept="acceptFileType"
            >
              <el-button type="text" v-loading.fullscreen.lock="fullscreenLoading">{{ uploadFileBtnText }}</el-button>
            </el-upload>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="showAddColumn" :label="addColumn.label" :width="addColumn.width" align="center">
        <template slot-scope="scope">
          <div v-html="scope.row.addColumnContent" @click="addColumnContentClick"></div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination v-if="showPagination" :initObj="initObj" @onChangePageData="handleChangePageData" />
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import PromptMessage from '@/components/PromptMessage'
import { mapGetters } from 'vuex'
export default {
  name: 'SimpleTable',
  components: {
    Pagination
  },
  computed: {
    itemsShowDelete() {
      return this.chooseList.length > 0 ? true : false
    },
    showUp() {
      if (this.contentData.number) {
        return (this.contentData.number !== 0 || (this.contentData.number === 0 && this.chooseCurrentIndex !== 0)) && this.chooseList.length === 1 ? true : false
      } else {
        if (this.chooseList.length === 1) {
          return this.chooseCurrentIndex === 0 ? false : true
        } else {
          return false
        }
      }
    },
    showBottom() {
      if (this.contentData.number || this.contentData.number === 0) {
        let status =
          (this.contentData.number !== this.contentData.totalPages - 1 || (this.contentData.number === this.contentData.totalPages - 1 && this.chooseCurrentIndex !== this.tableData.length - 1)) &&
          this.chooseList.length === 1
        return status
      } else {
        if (this.chooseList.length === 1) {
          return this.chooseCurrentIndex === this.tableData.length - 1 ? false : true
        } else {
          return false
        }
      }
    },
    listBtnClass() {
      return this.showListBtn ? (this.isShowMore ? 'auto-height' : 'fixed-height') : ''
    },
    showPagination() {
      if (this.showListBtn) {
        return this.isShowMore && this.tableData.length > 0
      } else {
        return !this.noPagination && this.tableData.length > 0
      }
    },
    deleteDisabled() {
      if (this.showDelete && this.itemsShowDelete && this.tableData.length > 0 && this.chooseList.length > 0) {
        let status = this.chooseCurrentIndex ? this.tableData[this.chooseCurrentIndex].isPublish : ''
        return status && status === '已发布' ? true : false
      } else {
        return true
      }
    },
    nonePointerEvents() {
      return this.disabledEvents ? 'none-pointer-events' : ''
    },
    ...mapGetters({
      magId: 'magId'
    })
  },
  props: {
    initObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showBorder: {
      type: Boolean,
      default: false
    },
    popContent: {
      type: String,
      default: ''
    },
    columnData: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSerial: {
      type: Boolean,
      default: false
    },
    uniqueIdName: {
      type: String,
      default: 'id'
    },
    maxHeight: {
      type: String,
      default: '1000'
    },
    chartTable: {
      type: Boolean,
      default: false
    },
    noPagination: {
      type: Boolean,
      default: false
    },
    hasSelect: {
      // 是否显示多个选择
      type: Boolean,
      default: false
    },
    showChoose: {
      // 是否显示单个选择
      type: Boolean,
      default: false
    },
    showDelete: {
      // 是否显示删除
      type: Boolean,
      default: false
    },
    showUpBottom: {
      // 是否显示上移下移按钮
      type: Boolean,
      default: false
    },
    contentData: {
      // 返回的数据
      type: Object,
      default: () => {
        return {}
      }
    },
    showOperate: {
      // 是否显示操作
      type: Boolean,
      default: false
    },
    showOperateFixed: {
      type: [Boolean, String],
      default: false
    },
    showOperateText: {
      // 是否显示操作文字，默认操作
      type: String,
      default: '操作'
    },
    showBtn1: {
      // 是否显示操作按钮一
      type: Boolean,
      default: true
    },
    showBtn2: {
      // 是否显示操作按钮二
      type: Boolean,
      default: true
    },
    showBtn3: {
      // 是否显示操作按钮三
      type: Boolean,
      default: false
    },
    showBtn4: {
      // 是否显示操作按钮四
      type: Boolean,
      default: false
    },
    btn1Text: {
      // 操作按钮一Text
      type: String,
      default: '按钮一'
    },
    btn2Text: {
      // 操作按钮二Text
      type: String,
      default: '按钮二'
    },
    btn3Text: {
      // 操作按钮三Text
      type: String,
      default: '按钮三'
    },
    btn4Text: {
      // 操作按钮四Text
      type: String,
      default: '按钮四'
    },
    uploadAcceptType: {
      // 操作上传按钮接受类型
      type: String,
      default: '.png,.jpg,.jpeg'
    },
    showListBtn: {
      // 是否显示任务列表
      type: Boolean,
      default: false
    },
    isDisabledClick: {
      // 是否存在禁止点击
      type: Boolean,
      default: false
    },
    showPreview: {
      // 显示操作中的预览按钮
      type: Boolean,
      default: false
    },
    showUpload: {
      // 显示操作中的上传按钮
      type: Boolean,
      default: false
    },
    uploadUrl: {
      // 操作中的上传的url
      type: String,
      default: ''
    },
    uploadText: {
      // 操作中的上传的按钮文字
      type: String,
      default: '上传'
    },
    disabledEvents: {
      // 是否禁用table点击事件
      type: Boolean,
      default: false
    },
    serialText: {
      // 序号的文字
      type: String,
      default: '序号'
    },
    // 操作按钮是否显示tooltip
    showOperateTooltip: {
      type: Boolean,
      default: false
    },
    // 操作按钮是否显示tooltip内容
    showOperateTooltipContent: {
      type: String,
      default: ''
    },
    // 操作按钮是否显示tooltip的标题
    showOperateTooltiplabel: {
      type: String,
      default: ''
    },
    // 操作按钮宽度
    showOperateWidth: {
      type: String,
      default: '120px'
    },
    // 是否显示上传file文件
    showUploadFile: {
      type: Boolean,
      default: false
    },
    // 上传file文件form表单
    fileUploadForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 上传file文件url
    fileUploadUrl: {
      type: String,
      default: ''
    },
    // 上传file文件类型
    acceptFileType: {
      type: String,
      default: '.xls, .xlsx'
    },
    // 上传file文件按钮文字
    uploadFileBtnText: {
      type: String,
      default: '浏览'
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    showBtnText: {
      type: String,
      default: '按钮'
    },
    btnText: {
      type: String,
      default: '查看'
    },
    // 是否显示添加的列
    showAddColumn: {
      type: Boolean,
      default: false
    },
    // 添加的列传入的数据
    addColumn: {
      type: Object,
      default: () => {
        return {}
      }
    },
    alignText: {
      // 文字居左
      type: String,
      default: ''
    },
    isHighlight: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    popContent(nv) {
      this.popContentVal = nv
    },
    tableData(n) {
      if (n) {
        this.constantTableData = n
      }
    }
  },
  data() {
    return {
      showQrcode: false,
      showQrcodeText: '',
      morImg: require('@/assets/img/img_default.png'),
      PromptMessage,
      popContentVal: '',
      content: '',
      chooseList: [], // 当前选中文章列表
      chooseRow: '', // 选中单行数据
      chooseCurrentIndex: null, // 当前选中index
      isShowMore: true,
      chooseId: '',
      constantTableData: [],
      deleteDialogVisible: false,
      fullscreenLoading: false, // 等待loading 是否开启
      chooseDateTimePicker: '',
      currentItem: '',
      currentRow: {},
      test: ''
    }
  },
  mounted() {
    if (this.tableData) {
      this.constantTableData = this.tableData
    }
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    initData() {
      this.chooseId = ''
      this.chooseCurrentIndex = null
      this.chooseList = []
      this.chooseRow = ''
    },
    // 需要点击获取pop内容的
    handleSearchPopContent(chooseItem = {}) {
      this.$emit('onClickPopIcon', chooseItem)
    },
    // 需要点击操作的
    handleClickTitle(chooseColumnItem = {}, chooseItem) {
      this.$emit('onClickTitle', chooseItem, chooseColumnItem)
    },
    // 页码改变了之后的操作
    handleChangePageData(pageObj) {
      this.$emit('onChangePageData', pageObj)
    },
    // 排序字段改变
    handleChangeSort(val) {
      const { prop, order } = val
      this.$emit('onChangeSort', prop, order)
    },
    clearSort() {
      this.$refs.simpleTable.clearSort()
    },
    handleSelect(val, row) {},
    handleSelectionChange(val) {
      this.chooseList = val
      if (val) {
        if (val.length === 1) {
          this.tableData.map((item, index) => {
            if (val[0].id === item.id) {
              this.chooseRow = item
              this.chooseCurrentIndex = index
            }
          })
        }
        this.$emit('onChangeSelection', val, this.chooseList)
      }
    },
    //切换为input框
    changeInputContent(val, prop, e, index, scope) {
      this.tableData.map((item, index) => {
        this.$set(item, 'isSet', false)
        this.$set(item, 'rowIndex', null)
      })
      this.columnData.map((it, rowIndex) => {
        if (it.prop === prop) {
          this.$set(this.tableData[index], 'rowIndex', rowIndex)
        }
      })
      this.$set(this.tableData[index], 'isSet', true)
      this.$nextTick(() => {
        this.$refs.editInput[0].focus()
      })
      this.content = val
      this.$emit('onChangeInput', val, prop, e, index)
    },
    //input改变事件
    changeInput(val, prop, e, index) {
      let currentId = this.tableData[index].id
      this.$emit('onChangeInputBlur', val, prop, e, index, this.content, currentId)
    },
    //input失焦事件
    changeInputBlur() {
      this.tableData.map(item => {
        this.$set(item, 'isSet', false)
        this.$set(item, 'rowIndex', null)
      })
    },
    // 清空多选
    clearSelection() {
      this.$refs.simpleTable.clearSelection()
    },
    // 上移
    toTop() {
      this.$emit('onChangeToTop', this.chooseCurrentIndex, this.chooseId, this.tableData[this.chooseCurrentIndex])
    },
    // 下移
    toBottom() {
      this.$emit('onChangeToBottom', this.chooseCurrentIndex, this.chooseId, this.tableData[this.chooseCurrentIndex])
    },
    // 重新计算chooseCurrentIndex
    cumputedChooseCurrentIndex(row) {
      this.chooseList = []
      this.chooseList.push(row)
      this.chooseId = row.id
      this.tableData.map((item, index) => {
        if (row.id === item.id) {
          this.chooseCurrentIndex = index
        }
      })
      this.$refs.simpleTable.setCurrentRow(row)
      // this.chooseList = []
      // this.chooseList.push(row)
      // this.chooseId = row.id
    },
    // 删除
    deleteItems() {
      let ids = ''
      this.chooseList.map((item, index) => {
        ids = ids + item.id + (index === this.chooseList.length - 1 ? '' : ',')
      })
      this.$emit('onChangeDeleteItems', ids)
      this.deleteDialogVisible = false
    },
    // 操作按钮一
    btn1Click(item) {
      if (!(this.isDisabledClick && !item.activeBtn1)) {
        this.$emit('onClickBtn1', item)
      }
    },
    // 操作按钮二
    btn2Click(item) {
      if (!(this.isDisabledClick && !item.activeBtn2)) {
        this.$emit('onClickBtn2', item)
      }
    },
    // 操作按钮三
    btn3Click(item) {
      this.$emit('onClickBtn3', item)
    },
    // 操作按钮四
    btn4Click(item) {
      this.$emit('onClickBtn4', item)
    },
    btnClick(item) {
      if (item.activeBtn) {
        this.$emit('onClickBtn', item)
      }
    },
    // 推送结果选中行改变
    handleCurrentChange(val) {
      // 选中行
      if (val) {
        // 设置radio选中
        this.$emit('onChangeRow', val)
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex
    },
    // 点击每一行
    handleRowClick(row, column) {
      if (this.showChoose) {
        this.chooseList = []
        this.chooseList.push(row)
        this.chooseId = row.id
        this.tableData.map((item, index) => {
          if (row.id === item.id) {
            this.chooseCurrentIndex = index
          }
        })
        this.$emit('onRowClick', this.chooseId, row, this.chooseList)
      }
    },
    showMore() {
      if (this.isShowMore) {
        this.isShowMore = false
        this.constantTableData = this.chooseList
      } else {
        this.isShowMore = true
        this.constantTableData = this.tableData
      }
    },
    // 点击radio
    clickRadio(item, index) {
      this.$emit('onRadioClick', item, index)
    },
    // 表格预览
    handlePreviewURL(item) {
      this.$emit('onHandlePreviewURL', item)
    },
    // 表格预览跳转
    handlePreviewJump(item) {
      this.$emit('onHandlePreviewJump', item)
    },
    uploadClick(row) {
      this.currentItem = row
    },
    // 成功上传
    handleUploadSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.$emit('onHandleUploadSuccess', ID, URL.createObjectURL(file.raw), this.currentItem)
    },
    notRenderHeaderMethods(h, { column }) {
      return column.label
    },
    // tooptip处理函数
    renderHeaderMethods(h, { column, $index }) {
      let _this = this
      return h(
        'div',
        {
          style: 'display:flex;margin:auto;justify-content:center'
        },
        [
          h('span', column.label),
          h(PromptMessage, {
            // 引用 promptMessages 组件
            props: {
              messages: [_this.columnData[$index - 2].tooltipContent],
              isIcon: _this.columnData[$index - 2].tooltipIsIcon,
              text: _this.columnData[$index - 2].tooltipText
            }
          })
        ]
      )
    },
    // 操作按钮tooptip处理函数
    showOperateRenderHeaderMethods(h, { column, $index }) {
      let _this = this
      return h(
        'div',
        {
          style: 'display:flex;margin:auto;justify-content:center'
        },
        [
          h('span', column.label),
          h(PromptMessage, {
            // 引用 promptMessages 组件
            props: {
              isIcon: false,
              text: _this.showOperateTooltiplabel,
              isTooltipImg: true,
              tooltipImg: _this.showOperateTooltipContent
            }
          })
        ]
      )
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回false或者返回Promise且被reject,则停止上传
    beforeUploadFile(file) {
      let fileType = ''
      let arr = []
      if (this.acceptFileType.indexOf(',') !== -1) {
        this.acceptFileType.split(',').map(item => {
          arr.push(item.split('.')[1])
        })
      } else {
        arr = [this.acceptFileType.split('.')[1]]
      }
      let filePath = file.name
      let fileNameLength = file.name.lastIndexOf('.')
      if (fileNameLength != -1) {
        fileType = filePath.substring(fileNameLength + 1, filePath.length).toLowerCase()
      } else {
        fileType = ''
      }
      if (!arr.includes(fileType)) {
        this.$message({
          message: '请上传' + arr + '类型文件！',
          duration: 1500,
          type: 'error'
        })
        return false
      }
      this.fullscreenLoading = true
      this.$emit('beforeUploadFile', file)
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$refs.uploadExcel.clearFiles() // 清除上次上传记录
      if (res && res.status === 0) {
        this.fullscreenLoading = false
        this.$emit('uploadFileSuccess', res.data)
      } else {
        const { message = '' } = res
        fileList.pop()
        this.$message.error(message)
        this.fullscreenLoading = false // 等待loading 关闭
      }
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error(err.msg)
      this.fullscreenLoading = false // 等待loading 关闭
    },
    addColumnContentClick(event) {
      setTimeout(() => {
        this.$emit('addColumnClick', event)
      }, 200)
    },
    dateTimePickerChange(item) {
      this.$emit('dateTimePickerChange', item)
    },
    handleItemSelectChange(val, item, type, prop) {
      this.test = val
      this.$emit('handleItemSelectChange', val, item, type, prop)
    },
    isMagIdInClusterMagIds(row) {
      let ary = row.clusterMagIds.split(',')
      if (ary.includes(String(this.magId))) {
        return 'ft-w'
      }
    }
  }
}
</script>
<style lang="scss">
.el-tooltip__popper {
  font-size: 14px;
  max-width: 50%;
}

.el-table .cell {
  padding-right: 0;
}

.operate-btns {
  .el-button--text {
    line-height: 14px;
    text-indent: 0px;
    top: 0px;
    margin-left: 0px;
  }

  .el-button--small {
    padding: 0;
  }

  .disabledBtn {
    cursor: no-drop !important;

    &:hover {
      color: #c0c4cc !important;
    }
  }

  .upload {
    .el-button {
      background: none;
      border: none;
      color: #666;
      font-size: 14px;
      line-height: 24px;

      &:hover {
        color: #2644ca;
      }
    }
  }
}

.radio {
  .el-radio__label {
    display: none;
  }
}

.none-pointer-events {
  .el-table__body {
    pointer-events: none !important;
  }
}

.pm-taskList {
  .el-date-editor--datetime {
    .el-input__prefix {
      display: none;
    }

    .el-input__inner {
      border: none;
      background: none;
      cursor: pointer;
      text-align: center;

      &::placeholder {
        color: #2644ca;
        text-align: center;
      }
    }
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 140px;
  }

  .el-input--prefix .el-input__inner {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.clickUrl {
  cursor: pointer;
}

.choose-style {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #dcdfe6;
  box-sizing: initial;
  position: relative;
  cursor: pointer;
  display: block;
  margin: auto;

  .child {
    border-radius: 50%;
    background: white;
    width: 5px;
    height: 5px;
    position: absolute;
    top: 33%;
    left: 38%;
    box-sizing: initial;
  }
}

.choose-style:hover {
  border: 1px solid #2644ca;
}

.is-choose-style {
  width: 17px;
  height: 17px;
  background: #2644ca;
}

.delete {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 20px;
  align-items: center;
  width: 70px;
  height: 30px;
  justify-content: center;
  margin: 0 10px 10px 0;
  cursor: pointer;
  color: #91949d;

  span {
    margin-left: 4px;
  }

  &:hover {
    color: white;
    background-color: #265cca;
  }
}

.operate-btns {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .btn {
    cursor: pointer;

    &:hover {
      color: #265cca;
    }
  }

  .preview {
    cursor: pointer;

    &:hover {
      color: #2644ca;
    }
  }

  .preview-image {
    width: 24px;
    cursor: pointer;
  }
}

.btn_fx {
  padding: 14px 26px;
  text-align: center;
}

.list-btn {
  cursor: pointer;
  padding-left: 26px;
  display: inline-block;
  color: #2644ca;
  line-height: 36px;
  background: url('../../../public/imgs/icon_btn_list.png') left center no-repeat;
}

.auto-height {
  height: auto;
}

.fixed-height {
  height: 80px !important;
}

.tbTitle {
  pointer-events: all;
  cursor: pointer;
}
.btn1Class,
.btn3Class {
  color: #2644ca;
}
.ft-w {
  font-weight: bold;
}
</style>
