<template>
  <div class="header">
    <div class="z_success_box" id="z_success_box">
      <div>
        <p>转载成功！</p>
      </div>
    </div>
    <div class="w poss">
      <div class="header1 home_title">
        <div class="logo-wrapper" @click="goHome"></div>
        <div class="home_title1 btn1" v-if="showSettings">
          <el-select class="mag-list" v-if="showMagDialog" @change="setMag" v-model="magId" filterable
            placeholder="请选择">
            <template slot="prefix">
              {{(permissionList.find(s => s.magId === magId) || {}).platMagName}}
            </template>
            <el-option v-for="(item, index) in permissionList" :key="index" :label="item.platMagName"
              :value="item.magId" />
          </el-select>
          <div class="preview-push-results" @click="toPreviewPushResults" v-if="showTaskManagement">
            推送结果一览
          </div>
          <div class="task-management" v-if="showTaskManagement" @click="toPMTaskManagement">
            <el-badge :value="deploySize" class="badge">
              <img src="@/assets/img/task-management.png" alt="" />
              <span>任务管理</span>
            </el-badge>
          </div>
          <div style="text-align: right;" class="top_user_right">
            <span class="top_setUp" @click="showEmailSettingsDialog()">邮件设置</span>
<!--            <span v-if="showMagDialog" class="top_setUp" @click="showDialog()">设置</span>-->
            <span v-if="showMagDialog" class="icon_fg">|</span>
            <span class="userName">{{ realName }}</span>
            <span>，</span>
            <span class="out" @click="goOut">退出</span>
          </div>
        </div>
      </div>
    </div>
    <div class="w" v-if="topShow">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#333"
        active-text-color="#265cca" menu-trigger="hover" @select="handleSelect">
        <el-menu-item index="/analysis">期刊画像</el-menu-item>
        <el-submenu index="4">
          <template slot="title">邮件推送</template>
          <el-menu-item index="/pushResults"><span @click.stop="openInNewPage('/pushResults','4')">推送结果</span></el-menu-item>
          <el-menu-item index="/emailTaskManagement"><span @click.stop="openInNewPage('/emailTaskManagement','4')">任务管理</span></el-menu-item>
          <el-menu-item index="/whitelist"><span @click.stop="openInNewPage('/whitelist','4')">学者管理</span></el-menu-item>
          <el-menu-item index="/configure"><span @click.stop="openInNewPage('/configure','4')">基础配置</span></el-menu-item>
          <el-menu-item index="/emailTemplate"><span @click.stop="openInNewPage('/emailTemplate','4')">本刊模板</span></el-menu-item>
          <el-menu-item index="/outgoingMailboxManagement"><span @click.stop="openInNewPage('/outgoingMailboxManagement','4')">发件邮箱管理</span></el-menu-item>
          <el-menu-item index="/scholarDataEvaluation"><span @click.stop="openInNewPage('/scholarDataEvaluation','4')">学者测评</span></el-menu-item>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">微信传播</template>
          <el-menu-item index="/weChatTaskManagement"><span @click.stop="openInNewPage('/weChatTaskManagement','5')">任务管理</span></el-menu-item>
          <el-menu-item index="/basicConfiguration"><span @click.stop="openInNewPage('/basicConfiguration','5')">基础配置</span></el-menu-item>
          <el-menu-item index="/styleConfiguration"><span @click.stop="openInNewPage('/styleConfiguration','5')">本刊模板</span></el-menu-item>
          <el-menu-item index="/webJournalTemplate"><span @click.stop="openInNewPage('/webJournalTemplate','5')">通用组件模板</span></el-menu-item>
        </el-submenu>
        <el-menu-item index="/citationPush">传播表现推送</el-menu-item>
        <el-menu-item index="/annualReport">年度报告</el-menu-item>
      </el-menu>

    </div>
    <div class="header_setBox">
      <el-dialog :visible.sync="eldialogVisible" :fullscreen="true" title="" custom-class="setUpBox zkgd">
        <div class="selete_btns" slot="title">
          <p class="title">期刊领域设置</p>
          <el-radio v-model="radio" label="1">期刊引证领域</el-radio>
          <el-radio v-model="radio" label="2">核心刊领域</el-radio>
          <el-radio v-model="radio" label="3">自定义领域</el-radio>
          <div class="y_btn">
            <el-button type="primary" plain @click="saveDomainType">确定</el-button>
          </div>
        </div>
        <div class="setContens" :style="radio == 2 ? 'padding:30px' : ''">
          <p class="title">期刊领域设置</p>
          <div class="zk_title" v-if="radio == 1">
            <div>引证期刊</div>
            <div>引用期刊</div>
          </div>
          <div class="searchBox" v-if="radio == 3">
            <el-input v-model="searchInput" placeholder="搜索期刊" @keyup.enter.native="searchBtn"></el-input>
            <el-button icon="el-icon-search" circle @click="searchBtn"></el-button>
          </div>
          <div class="list_box">
            <ul class="linyugd clearfix" style="padding-right: 15px" v-if="radio == 1">
              <li :key="index" v-for="(item, index) in citationMagsList">
                <a v-if="!(radio == 3)">{{ item }}</a>
              </li>
            </ul>
            <ul class="linyugd clearfix" style="padding-left: 20px" v-if="radio == 1">
              <li :key="index" v-for="(item, index) in referenceMagsList">
                <a v-if="!(radio == 3)">{{ item }}</a>
              </li>
            </ul>
            <ul class="linyugd linyugd01 clearfix" v-if="radio == 2">
              <li :key="index" v-for="(item, index) in coreAreaList">
                <a v-if="!(radio == 3)">{{ item }}</a>
              </li>
            </ul>
            <ul class="linyugd linyugd01 clearfix linyugd_cheack" v-if="radio == 3">
              <li v-for="(item, index) in customField" :key="index">
                <el-checkbox v-model="checked[item]" ref="check" class="check" :name="item">
                  <a>{{ item }}</a>
                </el-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-dialog :visible.sync="emailSettingsVisible" width="70%" title="邮件设置" custom-class="email-dialog">
      <p class="export-setting" >
        <span @click="exportSettings">导出设置</span>
      </p>
      <SimpleTable
        class="emailTableList"
        ref="emailTableList"
        showAddColumn
        btn1Text="查看"
        serialText="优先级"
        showOperateWidth="150px"
        showOperateText="收件邮箱"
        showOperateTooltiplabel="导入样例"
        uploadFileBtnText="导入"
        :showBtn2="false"
        :tableData="emailTableData"
        :initObj="emailTablePageObj"
        :columnData="emailTableColumn"
        @onChangePageData="
          (pageObj) => {
            getEmailList(pageObj)
          }
        "
        @onChangeSort="
              (prop, order) => {
                handleChangeSort(prop, order)
              }
            "
      />
      <div slot="footer" class="dialog-footer" style="margin-top:30px">
        <el-button @click="emailSettingsVisible = false">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { GetEmailList } from '@/api/emailPush'
import { GetPermissionList, GetDomainType, GetSameCatMags, SaveDomainType, GetCustomDomainMags, GetDeploySize } from '@/api/index'
import { Logout } from '@/api/login'
import { GetDomainMags } from '@/api/analysis'
import SimpleTable from '@/components/SimpleTable'

import { mapActions, mapGetters } from 'vuex'
export default {
  components: {SimpleTable},
  data () {
    return {
      searchInput: '',
      permissionList: [],
      magId: null,
      tenantId: null,
      activeIndex: '',
      realName: sessionStorage.getItem('realName'),
      topShow: true,
      eldialogVisible: false,
      radio: null,
      input: '',
      citationMagsList: [],
      referenceMagsList: [],
      coreAreaList: [], // 核心领域列表
      customField: [], // 自定义领域列表
      checked: [],
      checkedList: [], // 用户自定义选择列表
      constantCustomField: [],
      deploySize: '',
      updateInterval: '',
      permissionsArr: ['PMTaskManagement', 'previewPushResultsList', 'previewPushResultsDetails', 'emailPreviewHtml', 'citationPushPreviewHtml'],
      emailSettingsVisible: false,
      emailTableData: [],
      emailTablePageObj: {
        page: 1,
        size: 10,
        sort: 'username'
      },
      emailTableColumn: [
        {
          title: '期刊名',
          prop: 'magName',
          notSortable: false
        },
        {
          title: '发件邮箱',
          prop: 'username',
          notSortable: true
        },
        {
          title: '日发送量',
          prop: 'limitCount',
          notSortable: true
        },
        {
          title: '间隔发送时长(s)',
          prop: 'intervalSecond',
          notSortable: true,
          width: '120px'
        },
        {
          title: '发件人姓名',
          prop: 'personalName',
          notSortable: true,
          edit: true,
          width: '90px'
        },
        {
          title: '收件邮箱规则',
          prop: 'receiveEmailHost',
          width: '170px',
          notSortable: true
          /*,
          tooltip: true,
          tooltipContent: 'founder@founder.com的邮箱域名需要填写founder.com，多个域名请以英文分号;隔开，例如founder.com;gmail.com，未填写的表示任意任域名的邮箱',
          tooltipIsIcon: false,
          tooltipText: '填写说明'*/
        },
        {
          title: '收件邮箱域名优先级',
          prop: 'receiveEmailHostSequence',
          width: '160px',
          notSortable: true
        }
      ],
    }
  },
  watch: {
    $route (to) {
      // 手动设置各个页面选中的导航
      if (['/analysis', '/details'].includes(to.path)) {
        this.activeIndex = '/analysis'
      }
    },
    radio (val) {
      this.searchInput = ''
      this.checkedList = []
      this.initCheck()
      if (val == 1) {
        this.getDomainMags()
      } else if (val == 2) {
        this.getSameCatMags()
      } else if (val == 3) {
        this.getDomainMags(3)
      }
    },
    searchInput (val) {
      if (val.length == 0) {
        this.getDomainMags(3)
      }
    },
    magId() {
      this.getEmailList()
    }
  },
  computed: {
    ...mapGetters({
      magsStatus: 'magsStatus'
    }),
    showTaskManagement () {
      // 管理员和超级管理员才有
      return (sessionStorage.getItem('role') == 1 || sessionStorage.getItem('role') == 2) && this.permissionsArr.indexOf(this.$route.name) === -1
    },
    showSettings () {
      if (window.location.href.indexOf('specialPushResults') != -1 || ['emailPreviewHtml', 'citationPushPreviewHtml'].includes(window.location.href)) {
        return false
      } else {
        return true
      }
    },
    // showMaglist() {
    //   // 产品任务管理页不显示
    //   return this.permissionsArr.indexOf(this.$route.name) === -1
    // },
    showMagDialog () {
      // 产品任务管理页不显示
      return this.permissionsArr.indexOf(this.$route.name) === -1
    }
  },
  mounted () {
    // 0:普通  1:超级管理员 2:管理员  3:销售  4:设计
    if (window.location.href.indexOf('specialPushResults') != -1 || this.permissionsArr.indexOf(this.$route.name) !== -1) {
      this.topShow = false
    } else {
      this.$nextTick(() => {
        this.topShow = true
        this.getPermissionList()
      })
      this.getDeploySize()
      let _this = this
      this.updateInterval = setInterval(function () {
        _this.getDeploySize()
      }, 300000) // 定时刷新
    }
    // 设置默认选中
    this.activeIndex = this.$route.path;
    // 根据地址栏的activeIndex设置
    // const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    // const activeIndex = urlParams.get('activeIndex');
    // if (activeIndex) {
    //   this.activeIndex = activeIndex;
    // }
    // // 不知道怎么处理，强写选中样式，后续专业前端再处理
    // setTimeout(() => {
    //   const lis = document.querySelectorAll('li.is-active');
    //   lis.forEach(li => {
    //     if (li.querySelector('.el-submenu__title')) {
    //       const title = li.querySelector('.el-submenu__title').innerText;
    //       console.log(title);
    //       if ((this.activeIndex == '4' && title != '邮件推送') || (this.activeIndex == '5' && title != '微信传播')) {
    //         li.classList.remove('is-active');
    //         li.querySelector('.el-submenu__title').style.borderBottom = 'none';
    //       }
    //     }
    //   });
    // }, 0);
    // console.log("mounted",this.activeIndex);
  },
  beforeDestroy () {
    clearInterval(this.updateInterval) // 清楚定时刷新，不清除会一直运行，关闭页面也会定时刷新
  },
  methods: {
    ...mapActions(['setMagId', 'setMemberId', 'setPlatMagId', 'setPlatTenantId', 'setPlatMagName', 'setMagsStatus']),
    initCheck () {
      // this.$nextTick(() => {
      //   this.$refs.check.map((item) => {
      //     item.isChecked = false
      //   })
      // })
      // this.customField.map((it, index) => {
      //   this.checked[index] = false
      // })
    },
    showDialog () {
      this.searchInput = ''
      this.checkedList = []
      this.getDomainType()
    },
    // 自定义搜索
    searchBtn () {
      if (this.searchInput) {
        let search = this.searchInput
        let arr = this.customField.map((item, index) => {
          if (item.indexOf(search) != -1) {
            return item
          }
        })
        this.customField = []
        arr.map((item, index) => {
          if (item && item != 'undefined') {
            this.customField.push(item)
          }
        })
      } else {
        this.$message.error('请输入搜索词')
      }
    },
    // 自定义保存
    saveDomainType () {
      let mags
      if (this.radio == 3) {
        this.checkedList = []
        this.$refs.check.map((item) => {
          if (item.isChecked) {
            this.checkedList.push(item.name)
          }
        })
        mags = JSON.stringify(this.checkedList).replace(/\[|]|"/g, '')
      }
      let params = {
        magId: this.magId,
        type: this.radio,
        mags: this.radio == 3 ? mags : ''
      }
      SaveDomainType(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success(res.data.message)
          this.eldialogVisible = false
          this.$store.commit('SET_MAGSSTATUS', !this.magsStatus)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取选中列表
    check (item) {
      if (this.checkedList.length > 0) {
        let chooseIndex = ''
        this.checkedList.map((it, index) => {
          if (it == item) {
            chooseIndex = index
          }
        })
        if (chooseIndex === 0) {
          this.checkedList.splice(chooseIndex, 1)
        } else if (chooseIndex && chooseIndex != '') {
          this.checkedList.splice(chooseIndex, 1)
        } else {
          this.checkedList.push(item)
        }
      } else {
        this.checkedList.push(item)
      }
    },
    // 获取刊物领域设置信息 1为引证引用关系，2为分类关系，3为自选
    getDomainType () {
      this.eldialogVisible = true
      let params = {
        magId: this.magId
      }
      GetDomainType(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { type } = res.data.data
          this.radio = String(type)
          if (this.radio == 1) {
            this.getDomainMags()
          } else if (this.radio == 2) {
            this.getSameCatMags()
          } else if (this.radio == 3) {
            this.customField = res.data.data.mags
            this.constantCustomField = this.customField
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取刊物领域 radio为1
    getDomainMags (num) {
      //自定义领域
      if (num == 3) {
        let params = {
          magId: this.magId
        }
        GetCustomDomainMags(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            this.customField = res.data.data
            this.constantCustomField = this.customField
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      } else {
        let params = {
          magId: this.magId
        }
        GetDomainMags(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { citationMags = [], referenceMags = [] } = res.data.data
            this.citationMagsList = citationMags
            this.referenceMagsList = referenceMags
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      }
    },
    // 获取分类刊物列表 radio为2
    getSameCatMags () {
      let params = {
        magId: this.magId
      }
      GetSameCatMags(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { mags } = res.data.data
          this.coreAreaList = mags
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    setMag (val) {
      const index = this.permissionList.findIndex((value) => {
        return value.magId === val
      })
      const data = this.permissionList[index]
      window.sessionStorage.setItem('magId', val)
      this.setMagId(data.magId)
      this.setMemberId(data.memberId)
      this.setPlatMagId(data.platMagId)
      this.setPlatTenantId(data.platTenantId)
      this.setPlatMagName(data.platMagName)
      this.setMagsStatus('false')
    },
    getPermissionList () {
      GetPermissionList().then((resp) => {
        let { data, status } = resp.data
        if (status === 0) {
          const magId = window.sessionStorage.getItem('magId')
          this.permissionList = data
          if (magId) {
            let findItem, findItemIndex
            this.permissionList.map((item, index) => {
              if (item.magId + '' === magId + '') {
                findItem = item
                findItemIndex = index
              }
            })
            if (findItem) {
              this.magId = magId - 0
              const magData = this.permissionList[findItemIndex]
              this.setMagId(magData.magId)
              this.setMemberId(magData.memberId)
              this.setPlatMagId(magData.platMagId)
              this.setPlatTenantId(magData.platTenantId)
              this.setPlatMagName(magData.platMagName)
            } else {
              this.magId = data && data.length ? data[0].magId : null
              window.sessionStorage.setItem('magId', this.magId)
              this.setMagId(this.magId)
              this.setMemberId(data[0].memberId)
              this.setPlatMagId(data[0].platMagId)
              this.setPlatTenantId(data[0].platTenantId)
              this.setPlatMagName(data[0].platMagName)
            }
          } else {
            this.magId = data && data.length ? data[0].magId : null
            window.sessionStorage.setItem('magId', this.magId)
            this.setMagId(this.magId)
            this.setMemberId(data[0].memberId)
            this.setPlatMagId(data[0].platMagId)
            this.setPlatTenantId(data[0].platTenantId)
            this.setPlatMagName(data[0].platMagName)
          }
          this.setMagsStatus('false')
        }
      })
    },
    handleSelect (key, keyPath) {
      // console.log(key, keyPath)
      this.$router.push(key)
    },
    goOut () {
      Logout().then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          // this.$message({
          //   type: 'error',
          //   message: data.message
          // })
        } else {
          sessionStorage.setItem('token', '')
          this.$router.push('/')
        }
      })
    },
    // 去产品页任务管理
    toPMTaskManagement () {
      let routeData = this.$router.resolve({
        name: 'PMTaskManagement'
      })
      window.open(routeData.href, '_blank')
    },
    // 获取任务管理未处理数
    getDeploySize () {
      let params = {}
      GetDeploySize(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.deploySize = res.data.data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 跳转推送结果一览页面
    toPreviewPushResults () {
      let routeData = this.$router.resolve({
        name: 'previewPushResultsList'
      })
      window.open(routeData.href, '_blank')
    },
    showEmailSettingsDialog() {
      this.emailSettingsVisible = true
    },
    // 获取邮箱列表
    getEmailList(pageParams = {}, type, item) {
      let { page = 1, size = 10 } = pageParams
      let params = {
        page: page - 1,
        size,
        sort: this.emailTablePageObj.sort,
      }
      GetEmailList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.emailTablePageObj = {
            ...this.emailTablePageObj,
            page,
            size,
            total: data.totalElements
          }
          this.emailTableData = data.content
          if (this.emailTableData.length > 0) {
            this.emailTableData.map((item, index) => {
              item.activeBtn1 = item.hasAddEmail
              item.refreshTime = item.refreshTime || '--'
              item.magAuthorEmailCount = item.magAuthorEmailCount || '--'
              /*item.addColumnContent =
                '<div class="addColumnContent">' +
                '<div class="top">' +
                '<div class="count">数量：' +
                item.magAuthorEmailCount +
                '</div>' +
                '<div class="refresh">刷新</div>' +
                '</div>' +
                '<div class="bottom">刷新时间：' +
                item.refreshTime +
                '</div>' +
                '</div>'*/
            })
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleChangeSort(sortValue, order){
      if (order == 'ascending') {
        this.emailTablePageObj.sort = 'magId'
      } else {
        this.emailTablePageObj.sort = 'username'
      }
      this.getEmailList()
    },
    exportSettings() {
      window.open(`${window.location.origin}/das-api/mag/email/exportAll`)
      /*GetexportAll().then((res) => {
        console.log(res)
      })*/
    },
    openInNewPage(path,index) {
      const url = `${window.location.origin}#${path}?activeIndex=${index}`;
      window.open(url, '_blank')
    },
    goHome() {
      // console.log('goHome')
      // this.$router.push('/analysis')
      window.open(this.$router.resolve({ path: '/analysis' }).href, '_blank');
    }
  }
}
</script>
<style scoped lang="scss">
.poss {
  position: relative;
}

.header1 {
  width: 100%;
  position: absolute;
  height: 120px;
  top: -120px;
  right: 0;
}
.home_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-wrapper {
    width: 44%;
    height: 49px;
    cursor: pointer;
  }
}

.home_title1 {
  display: flex;
  // position: absolute;
  top: 45px;
  right: 0;
  height: 30px;
  line-height: 24px;
  font-size: 14px;
  color: #bec7ef;
  text-align: left;

  .mag-list {
    min-width: 110px;
    margin-right: 20px;
    ::v-deep .el-input__prefix{
        position: relative;
        left: 0px;
        box-sizing: border-box;
        border: 1px solid #ffffff00;
        padding: 0 30px;
        visibility: hidden;
    }
    ::v-deep input{
      position: absolute;
    }
    ::v-deep .el-input__suffix {
      height: 24px;
    }
  }

  .task-management {
    .badge {
      margin-right: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 16px;
        margin-right: 6px;
      }
    }
  }

  .preview-push-results {
    margin-right: 20px;
    cursor: pointer;
  }
}

.header {
  // height: 180px;
  padding-top: 120px;
}

.el-menu.el-menu--horizontal {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #fff;
  overflow: hidden;
  background-color: #e4e9f7;
}

.el-menu--horizontal>.el-menu-item {
  font-weight: bold;
  /* background-color: #e4e9f7; */
  font-size: 20px;
  /* color: #333 !important; */
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
  /* color: #265cca !important; */
  border-bottom: 2px solid #fff !important;
}

.el-menu-item {
  width: 16.7%;
  padding: 0;
  text-align: center;
}

.out {
  cursor: pointer;
}

.out:hover {
  color: #fff;
}

.top_user_right span {
  position: relative;
  top: -7px;
}

.top_user_right .userName {
  top: 0;
}

.email-dialog {
  /deep/.el-dialog__body {
    max-height: 400px;
    overflow-y: scroll;
  }
  .export-setting {
    text-align: right;
    margin-bottom: 10px;
  }
  .export-setting span {
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.header {
  .el-dialog__wrapper {
    background: rgba(0, 0, 0, 0.7) !important;
    padding: 0 !important;
  }

  .task-management {
    .el-badge__content {
      height: 14px;
      line-height: 14px;
      padding: 0 4px;
    }
  }
}

.v-modal {
  display: none !important;
}

.cus-el-submenu.el-submenu {
  width: auto;
  text-align: center;
}

.cus-el-submenu .el-menu--horizontal {
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
}

.cus-el-submenu .el-menu--horizontal .el-menu--popup {
  width: auto !important;
  left: 0;
  border: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.cus-el-submenu .el-menu--horizontal .el-menu--popup::before {
  display: none;
}

.cus-el-submenu .el-menu--horizontal .el-menu .el-menu-item {
  min-width: auto;
}

.email-dialog {
  .el-dialog__body {
    max-height: 400px !important;
    overflow-y: scroll;
  }
}
</style>
